<template>
  <div style="height:100%">
    <vue-datamaps
      labels
      :geography-config="geographyConfig"
      :data="data"
      :fills="fills"
      popupTemplate
      @custom:popup="popupTemplate"
      :setProjection="setProjection('colombia')"
    >
      <div slot="hoverinfo" class="hoverinfo" style="white-space: pre-line;">
          <b>Departamento</b>: {{ popupData.departamento }}
          Cantidad de Mensajes {{ popupData.cantidad }}
      </div>
      
    </vue-datamaps>
    <input type="hidden" :value="obtenerDataMapa">
  </div>
</template>

<script>
import { VueDatamaps } from '../../custom_modules/vue-datamaps'
import * as d3 from 'd3v4'
export default {
  name: 'ListaNegra',
  components: {
    VueDatamaps
  },
  data () {
    let url = this.$apiAdress+'/dist/data/col.json';
    return {
      geographyConfig: {
        dataUrl: url,
        //popupOnHover: false,
        //highlightOnHover: false,
        //borderWidth: 3
      },
      scope: 'col',
      fills: {
        defaultFill: '#ABDDA4',
        gt50: d3.schemeCategory20[Math.floor(Math.random() * 20)],
        eq50: d3.schemeCategory20b[Math.floor(Math.random() * 20)],
        lt25: d3.schemeCategory20c[Math.floor(Math.random() * 20)],
        gt75: d3.schemeCategory20[Math.floor(Math.random() * 20)],
        lt50: d3.schemeCategory10[Math.floor(Math.random() * 10)],
        eq0: d3.schemeCategory10[Math.floor(Math.random() * 1)],
        pink: '#0fa0fa',
        gt500: d3.schemeCategory10[Math.floor(Math.random() * 1)]
      },
      data: {
        //'CO.CU': { fillKey: 'lt50' },
        //'CO.AM': { fillKey: 'lt50' }
      },
      popupData: {
        departamento: '',
        cantidad: '',
      }
    }
  },
  props:{
    codigoSeguimiento: '',
  },
  computed: {
    obtenerDataMapa(){
      if (this.codigoSeguimiento != "") {
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post("/sms/seguimiento/mapa/chart", {
            id: this.codigoSeguimiento,
          })
          .then((response) => {
            this.data = response.data;
          })
          .catch(() => {})
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      }
      return "OK";
    },
  },
  mounted() {
    //this.obtenerDataMapa();
  },
  methods: {
    popupTemplate ({ datum }) {
      if (datum == undefined) {
        this.popupData = {
          departamento: '',
          cantidad: 0,
        }
      }else{
        this.popupData = {
          departamento: datum.region,
          cantidad: datum.contador,
        }
      }
      //this.popupData.name = `Bubble for ${datum.name}`
    },
    setProjection (type) {
      const createProjection = {
        korea: function (d3, element) {
          const projection = d3.geoMercator().center([0, 0])
            .rotate([-128, -36])
            .scale(element.offsetWidth * 6)
            .translate([element.offsetWidth / 2, element.offsetHeight / 2])
          const path = d3.geoPath().projection(projection)
          return { projection, path }
        },
        zoom: function (d3, element) {
          var projection = d3.geoEquirectangular()
            .center([10, -3])
            .rotate([4.4, 0])
            .scale(element.offsetHeight * 0.6)
            .translate([element.offsetWidth / 2, element.offsetHeight / 2])
          var path = d3.geoPath()
            .projection(projection)
          return { path: path, projection: projection }
        },
        colombia: function (d3, element) {
          var projection = d3.geoEquirectangular()
            .center([1, 7])
            .rotate([74, 3.3])
            .scale(element.offsetWidth * 4)
            .translate([element.offsetWidth / 2, element.offsetHeight / 2])
          var path = d3.geoPath()
            .projection(projection)
          return { path: path, projection: projection }
        }
      }
      return createProjection[type]
    }
  }
}
</script>

<style>
.map {
    height: 100% !important;
}
</style>
