<template>
    <div>
        <CModal
            :show.sync="modal_progreso_mensaje"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="lg"
            color="dark"
            >
            <div>
              <CProgress
                :value="porcentaje"
                color="success"
                striped
                :animated="true"
                class="mb-2"
                />
                <br>
                <h5>Se esta procesando el cargue, por favor espere un momento. Gracias</h5>
            </div>
            <template #header>
                <h6 class="modal-title">Cargando Mensajes ...</h6>
                <CButtonClose @click="modal_progreso_mensaje = false" class="text-white"/>
            </template>
            <template #footer>
                <CButton @click="modal_progreso_mensaje = false" color="danger">Cerrar</CButton>
                <CButton @click="modal_progreso_mensaje = false" color="success" style="display:none;">Accept</CButton>
            </template>
        </CModal>

    </div> 
</template>

<script>
export default {
  name: 'ModalCargaMensaje',
  components: {
  },
  data () {
    return {
        modal_progreso_mensaje:false,
        countDown : 0,
        porcentaje:1,
        divide:0,
    }
  },
  props:{
    openModal: null,
  },
  methods: {
    countDownTimer() {
        if(this.countDown > 0) {
            setTimeout(() => {
                this.porcentaje += this.divide;
                this.countDown -= 1
                this.countDownTimer()
                if (this.countDown == 0) {
                    this.modal_progreso_mensaje=false;
                    this.$router.push({path:'/cargues'})
                }
            }, 1000)
        }
    }
  },
  created(){
    this.countDownTimer()
  },
  mounted(){
    this.$root.$on('abrirmodalProgresoMensaje', (param1,param2) => {
        this.countDown = param1;
        this.divide = 100/(param1-1);
        this.modal_progreso_mensaje = param2;
        this.countDownTimer();
    })
  },
}
</script>
