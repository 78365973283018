<template>
  <div>
    <CModal
      :show.sync="modal_abrir_multimedia"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <div>
        <!--index pantalla -->
        <template v-if="cambiar_pantalla == 1">
          <CRow>
            <CCol col="12">
              <CButton
                @click="cambiar_pantalla = 2"
                color="success"
                variant="outline"
                >Crear Codigo Multimedia</CButton
              >
              <hr />
              <span
                >Adicione el Codigo Multimedia en el texto que desea enviar, de
                esta forma sus usuarios podran acceder a los archivos</span
              >
              <hr />
            </CCol>
            <CCol col="12">
              <h5 class="text-center">
                Codigos Multimedias Creados [{{ items.length }}]
              </h5>
            </CCol>
            <CCol col="12">
              <CDataTable
                hover
                striped
                :items="items"
                :fields="fields"
                :items-per-page="5"
                :pagination="{ doubleArrows: false, align: 'center' }"
                @page-change="pageChange"
              >
                <template #accion="{ item, index }">
                  <td class="py-2">
                    <a
                      :href="item.url_corta"
                      target="_blank"
                      class="btn btn-outline-warning btn-sm"
                      >Ver Archivo</a
                    >
                  </td>
                  <td class="py-2">
                    <CButton
                      color="success"
                      variant="outline"
                      square
                      size="sm"
                      @click="
                        cambiar_pantalla = 3;
                        estadisticaMultimedias(item.codigo_unico);
                      "
                    >
                      Estadisticas
                    </CButton>
                  </td>
                  <td class="py-2">
                    <CButton
                      color="success"
                      variant="outline"
                      square
                      size="sm"
                      @click="
                        eliminarMultimedia(
                          item.id_multimedia,
                          item.codigo_plataforma
                        )
                      "
                    >
                      Eliminar
                    </CButton>
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </template>
        <!--crear pantalla -->
        <template v-if="cambiar_pantalla == 2">
          <CRow>
            <CCol col="12">
              <h5 class="text-center">Crear Codigo Multimedia</h5>
            </CCol>
            <CCol col="12">
              <CForm validate>
                <CRow>
                  <CCol col="12">
                    <br />
                    <CInput
                      label="Descripcion del archivo"
                      placeholder="Descripcion del archivo"
                      autocomplete="off"
                      v-model="nombre_archivo"
                      @keyup="limitarCaracteres"
                      maxlength="100"
                    />
                    <small>
                      Ingrese el texto para describir el archivo. No debe tener
                      simbolos como
                      <strong
                        >% & ñ = comillas dobles, comillas sencillas, ni
                        tildes.</strong
                      >
                      Máximo 100 caracteres. Este campo puede ir vacio.
                    </small>
                    <hr />
                    <CInputFile
                      label="Seleccione el archivo a enviar"
                      horizontal
                      @change="cargarArchivoEnvio"
                      :key="fileInputKey"
                    />
                    <small>
                      Imagenes (formato .jpg .png .gif), sonido (formato .mp3
                      .wav), video (formato .mp4), documentos (formato .pdf) no
                      mayor a 1 MB,
                      <strong
                        >entre menor tamaño el archivo sera más rapido de
                        acceder por los usuarios .</strong
                      >
                    </small>
                    <hr />
                  </CCol>
                  <CCol col="12">
                    <br />
                    <CRow>
                      <CCol col="3">
                        <CButton
                          @click="enviarInformacionEnvioMensaje"
                          color="success"
                          variant="outline"
                          >Crear Codigo</CButton
                        >
                      </CCol>
                      <CCol col="2">
                        <CButton
                          @click="cambiar_pantalla = 1"
                          color="warning"
                          variant="outline"
                          >Regresar</CButton
                        >
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CForm>
            </CCol>
          </CRow>
        </template>
        <!--estadistica pantalla -->
        <template v-if="cambiar_pantalla == 3">
          <CRow>
            <CCol col="12">
              <CButton
                @click="cambiar_pantalla = 1"
                color="warning"
                variant="outline"
                >Regresar</CButton
              >
            </CCol>
            <CCol col="12">
              <h5 class="text-center">Estadisticas Multimedia</h5>
            </CCol>
            <CCol col="12">
              <CDataTable
                hover
                striped
                :items="items_estadistica"
                :fields="fields_estadistica"
                :items-per-page="5"
                :pagination="{ doubleArrows: false, align: 'center' }"
                @page-change="pageChange"
              >
              </CDataTable>
            </CCol>
            <CCol col="12">
              <a
                :href="urlDownload"
                target="_blank"
                class="btn btn-outline-warning btn-sm"
                >Dearcagar Reporte [{{
                  items_estadistica[0] == undefined
                    ? 0
                    : items_estadistica[0].total
                }}]</a
              >
            </CCol>
          </CRow>
        </template>
      </div>
      <template #header>
        <h6 class="modal-title">Adjuntar Multimedia</h6>
        <CButtonClose
          @click="modal_abrir_multimedia = false"
          class="text-white"
        />
      </template>
      <template #footer>
        <CButton
          @click="modal_abrir_multimedia = false"
          color="danger"
          style="display: none"
          >Discard</CButton
        >
        <CButton
          @click="modal_abrir_multimedia = false"
          color="success"
          style="display: none"
          >Accept</CButton
        >
      </template>
    </CModal>

    <CToaster :autohide="5000" class="d-inline-block">
      <template v-for="toast in mostrarNotificacion">
        <CToast
          :key="'toast' + toast"
          :show="true"
          color="success"
          header="Notificacion Exitosa"
        >
          {{ mensaje_success }}
        </CToast>
      </template>
      <template v-for="toast1 in mostrarNotificacionAlerta">
        <CToast
          :key="'toast1' + toast1"
          :show="true"
          color="warning"
          header="Notificacion Alerta"
        >
          {{ mensaje_warning }}
        </CToast>
      </template>
      <template v-for="toast2 in mostrarNotificacionError">
        <CToast
          :key="'toast2' + toast2"
          :show="true"
          color="danger"
          header="Notificacion Alerta"
        >
          Error Comunicarse Con Soporte
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
export default {
  name: "MultimediaModal",
  components: {},
  data() {
    return {
      mostrarNotificacion: 0,
      mensaje_success: "Datos Guardado Correctamente.",
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      modal_abrir_multimedia: false,
      cambiar_pantalla: 1,
      fileInputKey: 0,
      nombre_archivo: null,
      archivo_envio: null,
      codigo_plataforma: 0,
      fields: [
        { key: "codigo_plataforma", label: "Código Multimedia" },
        {
          key: "fecha_creacion",
          label: "Fecha/hora Creación",
          _classes: "font-weight-bold",
        },
        {
          key: "archivo_descripcion",
          label: "Descripcion del archivo",
          _classes: "font-weight-bold",
        },
        {
          key: "accion",
          label: "Accion",
          _style: { width: "1%" },
          sorter: false,
          filter: false,
        },
      ],
      fields_estadistica: [
        { key: "total", label: "En toda Historia" },
        { key: "mes", label: "En el ultimo Mes" },
        {
          key: "semana",
          label: "En la ultima semana",
          _classes: "font-weight-bold",
        },
        { key: "dia", label: "En el ultimo dia", _classes: "font-weight-bold" },
        {
          key: "hora",
          label: "En las ultimas 2 horas",
          _classes: "font-weight-bold",
        },
      ],
      activePage: 1,
      items: [],
      items_estadistica: [],
      codigo_unico_download: "",
    };
  },
  props: {
    openModal: null,
  },
  methods: {
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    abrirModal() {
      this.modal_abrir_multimedia = this.openModal;
    },
    cargarArchivoEnvio(files, event) {
      this.archivo_envio = files[0];
      if (this.archivo_envio.name != undefined) {
        if (this.archivo_envio.size <= 1000000) {
          let extension = this.getFileExtension2(this.archivo_envio.name);
          extension = extension.toLowerCase();
          if (
            extension == "jpg" ||
            extension == "jpeg" ||
            extension == "png" ||
            extension == "gif" ||
            extension == "mp3" ||
            extension == "wav" ||
            extension == "mp4" ||
            extension == "pdf"
          ) {
          } else {
            this.fileInputKey++;
            this.archivo_envio = null;
            this.mostrarNotificacionAlerta++;
            this.mensaje_warning =
              "Solo se Permite Archivos JPG, JPEG, PNG, GIF, MP3, WAV, MP4 y PDF";
          }
        } else {
          this.fileInputKey++;
          this.archivo_envio = null;
          this.mostrarNotificacionAlerta++;
          this.mensaje_warning = "Solo se Permite Archivos de 1MB o Menor";
        }
      }
      //this.files = this.$refs.myFiles.files
    },
    getFileExtension2(filename) {
      return filename.split(".").pop();
    },
    limitarCaracteres(event) {
      let value = event.target.value;
      event.target.value = this.NumText(value);
      this.mensaje_enviar = this.NumText(value);
    },
    NumText(string) {
      //solo letras y numeros
      var out = "";
      //Se añaden las letras validas
      var filtro =
        "!<> $/()?@*_:;,.-1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM#"; //Caracteres validos
      for (var i = 0; i < string.length; i++)
        if (filtro.indexOf(string.charAt(i)) != -1) out += string.charAt(i);
      return out;
    },
    enviarInformacionEnvioMensaje() {
      if (this.archivo_envio != null) {
        let InstFormData = new FormData();
        InstFormData.append("archivo_envio", this.archivo_envio);
        InstFormData.append("descripcion", this.nombre_archivo);
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post("/sms/multimedia", InstFormData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.data == "OK") {
              this.mensaje_success = "Archivo Cargado Correctamente";
              this.mostrarNotificacion++;
              this.fileInputKey++;
              this.archivo_envio = null;
              this.nombre_archivo = null;
              this.obtenerMultimedias();
              this.cambiar_pantalla = 1;
            }
          })
          .catch(() => {})
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      } else {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "Debe Completar Los Datos Vacio";
      }
    },
    obtenerMultimedias() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/multimedia")
        .then((response) => {
          this.items = response.data;
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    estadisticaMultimedias(codigo_unico) {
      this.codigo_unico_download = codigo_unico;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/multimedia/estadistica", {
          codigo_unico: codigo_unico,
        })
        .then((response) => {
          this.items_estadistica = response.data;
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    eliminarMultimedias(id_multimedia) {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/multimedia/eliminar", {
          id_multimedia: id_multimedia,
        })
        .then((response) => {
          if (response.data == "OK") {
            this.mensaje_success = "Archivo Eliminado Correctamente";
            this.mostrarNotificacion++;
            this.obtenerMultimedias();
            this.cambiar_pantalla = 1;
          }
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    eliminarMultimedia(id_multimedia, codigo_plataforma) {
      // Use sweetalert2
      this.$swal
        .fire({
          title: `Desea Eliminar el Archivo Codigo : ${codigo_plataforma} ?`,
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.eliminarMultimedias(id_multimedia);
          }
        });
    },
  },
  computed: {
    urlDownload() {
      return (
        this.$apiAdress + "/multimedia/download/" + this.codigo_unico_download
      );
    },
  },
  mounted() {
    this.$root.$on("abrirModalLinkMultimedia", (param) => {
      this.modal_abrir_multimedia = param;
    });
    //this.abrirModal();
    this.obtenerMultimedias();
  },
};
</script>
