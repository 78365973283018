<template>
  <CContainer>
    <CRow>
      <CCol col="12">
        <h3>Mensajes de Texto</h3>
        <hr />
      </CCol>

      <CCol class="mb-3" col="12">
        <a>Tu primera Campaña ?</a>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <strong>Configura Tu Mensajes de Texto</strong>
          </CCardHeader>
          <CCardBody>
            <CInput
              label="1. Escribe el Nombre de Tu Campaña"
              placeholder="Nombre de La Campaña"
              autocomplete="off"
              v-model="nombre_campana"
              @keyup="limitarCaracteresV2"
            />
            <CRow form class="form-group">
              <CCol sm="12">
                <label for=""
                  >2. Escribe el Mensaje a Enviar -
                  <strong>Caracteres {{ mensaje_enviar.length }}</strong>
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  :maxlength="maximocaracteres"
                  v-model="mensaje_enviar"
                  @keyup="limitarCaracteres"
                ></textarea>
              </CCol>
            </CRow>
            <CRow form class="form-group">
              <CCol sm="12">
                <CSelect
                  label="Cantidad maxima de caracteres a enviar en un mismo mensaje"
                  required
                  was-validated
                  :value.sync="maximocaracteres"
                  :options="lista_parametros_caracteres"
                  @change="changeSelectCaracteres()"
                ></CSelect>
              </CCol>
            </CRow>

            <template v-for="(name, key) in radioNamesTipoEnvio">
              <CRow form class="form-group" :key="name">
                <CCol sm="12">
                  {{ name }}
                </CCol>
                <CInputRadioGroup
                  class="col-12"
                  :options="optionsTipoEnvio"
                  :custom="true"
                  :checked="`Option ${key}`"
                  :inline="key % 2 === 1"
                  :checked.sync="tipo_envio"
                />
                <small class="m-1">Horario: {{descripcionHorarioTipoEnvio}}</small>
              </CRow>
            </template>
            <template v-for="(name, key) in radioNamesHorario">
              <CRow form class="form-group" :key="name">
                <CCol col="12">
                  {{ name }}
                </CCol>
                <CCol col="12">
                  <div role="group" class="custom-control custom-radio">
                    <input
                      id="uid-ip8fkjcnavi"
                      type="radio"
                      name="radio61"
                      @change="changeRadioHorario($event)"
                      class="custom-control-input"
                      value="inmediato"
                      :disabled="disabled_fuera_horario"
                    />
                    <label for="uid-ip8fkjcnavi" class="custom-control-label">
                      Inmediato
                    </label>
                  </div>
                </CCol>
                <CCol col="12">
                  <CRow>
                    <CCol col="3">
                      <div role="group" class="custom-control custom-radio">
                        <input
                          id="uid-ip8fkjcnasjl"
                          type="radio"
                          name="radio61"
                          @change="changeRadioHorario($event)"
                          class="custom-control-input"
                          value="programado"
                        />
                        <label
                          for="uid-ip8fkjcnasjl"
                          class="custom-control-label"
                        >
                          Programado
                        </label>
                      </div>
                    </CCol>
                    <CCol col="9">
                      <input
                        type="datetime-local"
                        name="date-radio61"
                        class="form-control"
                        id="uid-ip8fkjcnasgh"
                        :min="dateValue"
                        v-model="dateValue"
                        :disabled="fechaHorarioDisabled"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </template>
            <CRow>
              <CCol sm="12">
                <label for="">5. Opciones Adicionales</label>
              </CCol>
              <CCol col="6">
                <CButton
                  color="dark"
                  variant="outline"
                  square
                  size="sm"
                  @click="abrirmodalLinkMultimedia()"
                >
                  Link Multimedia
                </CButton>
                <br />
                <br />
              </CCol>
              <CCol col="6">
                <CButton
                  color="dark"
                  variant="outline"
                  square
                  size="sm"
                  @click="abrirmodalSeguimientoLink()"
                >
                  Seguimiento Link
                </CButton>
                <br />
                <br />
              </CCol>
            </CRow>
            <template v-for="(name, key) in radioNamesMetodoEnvio">
              <CRow form class="form-group" :key="name">
                <CCol sm="12">
                  {{ name }}
                </CCol>
                <CInputRadioGroup
                  class="col-12"
                  :options="optionsMetodoEnvio"
                  :custom="true"
                  :checked="`Option ${key}`"
                  :inline="key % 2 === 0"
                  :checked.sync="metodo_envido_checked_radio"
                />
              </CRow>
            </template>
            <template v-if="changeRadioMetodoEnvio == 1">
              <hr />
              <CRow>
                <CCol col="12">
                  <div class="form-group">
                    <label for="">
                      Los nùmeros debe ser separados por punto y coma (;) y con
                      el indicativo del Pais para envios internacionales Ejemplo
                      a Colombia, Venezuela , Panama y USA:
                      3102111111;584162339846;50763552630;016788890723
                    </label>
                    <textarea
                      class="form-control"
                      rows="5"
                      v-model="numeros_individual"
                      onkeypress="return ( (event.charCode !=8 && event.charCode ==0) || event.keyCode ==59 || (event.charCode >= 48 && event.charCode <= 57))"
                    ></textarea>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <div class="form-group">
                    <label for="">Autorización de envio</label>
                    <CRow class="justify-content-center">
                      <CCol class="col-1">
                        <CSwitch
                          class="mr-1"
                          color="success"
                          shape="pill"
                          variant="opposite"
                          :checked.sync="autorizacion"
                        />
                      </CCol>
                      <CCol class="col-11">
                        <p>
                          Certifico que tengo autorización por escrito por parte
                          de los usuarios para enviar estos mensajes.
                        </p>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>
                <CCol col="12">
                  <div class="form-group">
                    <CButton
                      color="primary"
                      block
                      :disabled="isDisabled"
                      @click="enviarInformacionEnvioMensaje"
                    >
                      Enviar Mensajes
                    </CButton>
                  </div>
                </CCol>
              </CRow>
            </template>
            <template v-if="changeRadioMetodoEnvio == 2">
              <hr />
              <CRow>
                <CCol col="12">
                  <label for=""
                    >Carga una base de datos en formato Excel o CSV (archivo
                    separado por ,)</label
                  >
                </CCol>
                <CCol col="12">
                  <CInputFile
                    label="File input"
                    horizontal
                    @change="cargarArchivoEnvio"
                    :key="fileInputKey"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <div class="form-group">
                    <label for="">Autorización de envio</label>
                    <CRow class="justify-content-center">
                      <CCol class="col-1">
                        <CSwitch
                          class="mr-1"
                          color="success"
                          shape="pill"
                          variant="opposite"
                          :checked.sync="autorizacion"
                        />
                      </CCol>
                      <CCol class="col-11">
                        <p>
                          Certifico que tengo autorización por escrito por parte
                          de los usuarios para enviar estos mensajes.
                        </p>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>
                <CCol col="12">
                  <div class="form-group">
                    <CButton
                      color="primary"
                      block
                      :disabled="isDisabled"
                      @click="enviarInformacionEnvioMensaje"
                    >
                      Enviar Mensajes
                    </CButton>
                  </div>
                </CCol>
              </CRow>
            </template>
            <template v-if="changeRadioMetodoEnvio == 3">
              <hr />
              <CRow>
                <CCol col="12">
                  <strong
                    >Para ingresar el nombre del contacto, incluir dentro del
                    mensaje y en mayuscula %B%</strong
                  >
                </CCol>
                <CCol col="12">
                  <hr />
                  <!--<CSelect
                        label="Seleccionar Grupo"
                        required
                        was-validated
                        :value.sync="grupo_seleccionado"
                        :options="items_grupos"
                  ></CSelect>-->
                  <multiselect
                    v-model="grupo_seleccionado"
                    placeholder="Seleccionar Grupo"
                    label="name"
                    track-by="code"
                    :options="items_grupos"
                    :multiple="true"
                    :taggable="true"
                  ></multiselect>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <div class="form-group">
                    <label for="">Autorización de envio</label>
                    <CRow class="justify-content-center">
                      <CCol class="col-1">
                        <CSwitch
                          class="mr-1"
                          color="success"
                          shape="pill"
                          variant="opposite"
                          :checked.sync="autorizacion"
                        />
                      </CCol>
                      <CCol class="col-11">
                        <p>
                          Certifico que tengo autorización por escrito por parte
                          de los usuarios para enviar estos mensajes.
                        </p>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>
                <CCol col="12">
                  <div class="form-group">
                    <CButton
                      color="primary"
                      block
                      :disabled="isDisabled"
                      @click="enviarInformacionEnvioMensaje"
                    >
                      Enviar Mensajes
                    </CButton>
                  </div>
                </CCol>
              </CRow>
            </template>
          </CCardBody>
          <!--<CCardFooter>
          
        </CCardFooter>-->
        </CCard>
      </CCol>
      <CCol col="6">
        <CCard>
          <!--<CCardHeader>
            <strong>Precios de Sms</strong>
          </CCardHeader>-->
          <CCardBody>
            <img :src="imagenAyuda" width="100%" />
          </CCardBody>
          <!--<CCardFooter>
          
        </CCardFooter>-->
        </CCard>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            {{ mensaje_success }}
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
      <input type="hidden" v-model="changeRadioTipoEnvio">
      <MultimediaModal :openModal="false" />
      <ModalCargaMensaje :openModal="true" />
      <SeguimientoModal :openModal="false" />
      <!--<div :style="'width: 100%;height: 100%;top: 0;left: 0;position: fixed;display: flex;opacity: 0.5;background-color: #fff;z-index: 1000;text-align: center;justify-content: center;align-items: center;visibility:'+'hidden'">
            <CSpinner
              style="width:8rem;height:8rem;"
              color="info"
              grow
            />
          </div>-->
    </div>
  </CContainer>
</template>

<script>
import MultimediaModal from "./MultimediaModal.vue";
import SeguimientoModal from "./SeguimientoModal.vue";
import ModalCargaMensaje from "./ModalCargaMensaje.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "MensajesTexto",
  components: {
    MultimediaModal,
    Multiselect,
    ModalCargaMensaje,
    SeguimientoModal,
  },
  data() {
    return {
      mostrarNotificacion: 0,
      mensaje_success: "Datos Guardado Correctamente.",
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      optionsTipoEnvio: [
        { label: "1 Via", value: "1via" },
        {
          label: "2 Via (Proximamente)",
          value: "2via",
          props: { disabled: true },
        },
        {
          label: "Flash (Proximamente)",
          value: "flash",
          props: { disabled: true },
        },
      ],
      radioNamesTipoEnvio: ["3. Elige el Tipo de Envio"],
      radioNamesHorario: ["4. Selecciona el Horario de Envio"],
      fechaHorarioDisabled: true,
      dateValue: new Date().toISOString().split(".")[0],
      optionsMetodoEnvio: [
        { label: "Individual", value: "individual" },
        { label: "Cargue Archivo", value: "archivo" },
        { label: "Mis Grupos", value: "grupos", props: { disabled: true } },
      ],
      radioNamesMetodoEnvio: ["6. Selecciona el Metodo de Envio"],
      items: [],
      items_filter: [],
      items_grupos: [],
      activePage: 1,
      autorizacion: false,
      maximocaracteres: 152,
      grupo_seleccionado: "",
      mensaje_enviar: "",
      nombre_campana: "",
      tipo_envio: "1via",
      tipo_hora_envio: "",
      metodo_envido_checked_radio: "",
      fecha_envio: new Date().toISOString().split(".")[0],
      archivo_envio: null,
      fileInputKey: 0,
      numeros_individual: "",
      parametros_config: {
        horario_envio_msg: null,
        precio_seguimiento_link: 0.5,
      },
      disabled_fuera_horario: false,
      openModalMultimedia: false,
      longitud_firma_sms: 8,
      listaTipoEnvioConfig:[],
      descripcionHorarioTipoEnvio:"",
      lista_parametros_caracteres:
      [
        {
          value: '152',
          label:
            'Desde 1 hasta 152 Caracteres (Se cobra el valor de 1 SMS)',
        },
        {
          value: '298',
          label:
            'Desde 161 Hasta 298 Caracteres (Se cobra el valor de 2 SMS)',
        },
        {
          value: '451',
          label:
            'Desde 307 Hasta 451 Caracteres (Se cobra el valor de 3 SMS)',
        },
        {
          value: '604',
          label:
            'Desde 460 Hasta 604 Caracteres (Se cobra el valor de 4 SMS)',
        },
        {
          value: '757',
          label:
            'Desde 613 Hasta 757 Caracteres (Se cobra el valor de 5 SMS)',
        },
      ],
    };
  },
  methods: {
    changeRadioHorario(event) {
      var optionText = event.target.value;
      if (optionText == "programado") {
        this.fechaHorarioDisabled = false;
        this.tipo_hora_envio = optionText;
        let fecha_programa = new Date();
        fecha_programa.setDate(fecha_programa.getDate());
        this.dateValue = this.toISOLocal(fecha_programa).split(".")[0];
      } else {
        this.fechaHorarioDisabled = true;
        this.tipo_hora_envio = optionText;
      }
      //console.log(this.dateValue);
    },
    validateMinDate(event) {
      this.dateValue = event.target.value;
      /*let fecha_hoy = new Date().toISOString().split(".")[0];
      let fecha_digitada = event.target.value;
      fecha_hoy = fecha_hoy.split("T")[0];
      fecha_digitada = fecha_digitada.split("T")[0];
      if (fecha_digitada < fecha_hoy) {
        let fecha_programa = new Date();
        fecha_programa.setDate(fecha_programa.getDate());
        this.dateValue = this.toISOLocal(fecha_programa).split(".")[0];
      }*/
    },
    validaFechaProgramada(event) {
      var fecha_seleccionada = event.target.value;
      console.log(fecha_seleccionada);
      //14/9/2021, 5:45:33 p. m.
      //2021-09-14T22:49:33 2021-09-14T17:52:30
      let date = new Date().toISOString().split(".")[0];
      //console.log(date);
    },
    updateValue: function (value) {
      this.dateValue = value;
      //this.$emit('input', value);
    },
    calcTime(offset) {
      let d = new Date();
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      let nd = new Date(utc + 3600000 * offset);
      console.log(nd.toLocaleString());

      return nd;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);

      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        sign +
        z((off / 60) | 0) +
        ":" +
        z(off % 60)
      );
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    changeSelectCaracteres() {
      let texto = this.mensaje_enviar.substr(0, this.maximocaracteres);
      this.mensaje_enviar = texto.trim();
      this.mostrarNotificacion++;
      this.mensaje_success = `Ahora podras enviar hasta ${this.maximocaracteres} caracteres en el mensaje.`;
    },
    getGrupos() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/envio/grupos")
        .then((response) => {
          let data = response.data;
          for (const iterator of data) {
            //this.items_grupos.push({value:iterator.id_grupo,label:iterator.nombre_grupo})
            this.items_grupos.push({
              code: iterator.id_grupo,
              name:
                iterator.nombre_grupo +
                ` (${this.$formatterNumeroTipoCreditos(iterator.cantidad)})`,
            });
          }
          if (this.items_grupos.length > 0) {
            this.optionsMetodoEnvio = [
              { label: "Individual", value: "individual" },
              { label: "Cargue Archivo", value: "archivo" },
              {
                label: "Mis Grupos",
                value: "grupos",
                props: { disabled: false },
              },
            ];
            //this.grupo_seleccionado = this.items_grupos[0].code
            //console.log(this.grupo_seleccionado);
          }
        })
        .catch(() => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    cargarArchivoEnvio(files, event) {
      this.archivo_envio = files[0];
      if (this.archivo_envio.name != undefined) {
        let extension = this.getFileExtension2(this.archivo_envio.name);
        if (extension == "csv" || extension == "xlsx" || extension == "xls") {
          if (extension == "csv") {
            this.mostrarNotificacionAlerta++;
            this.mensaje_warning =
              "Señor(a) Usuario Recuerde que los Archivos Cargados por CSV, los valores deben estar separados por coma (,)";
          }
        } else {
          this.fileInputKey++;
          this.archivo_envio = null;
          this.mostrarNotificacionAlerta++;
          this.mensaje_warning = "Solo se Permite Archivos CSV , XLSX y XLS";
        }
      }
      //this.files = this.$refs.myFiles.files
    },
    getFileExtension2(filename) {
      return filename.split(".").pop();
    },
    enviarInformacionEnvioMensaje() {
      //let validaFecha = this.validarFueraDeHorario();
      let validaFecha = this.calculaHoraProgramacion();
      let validaForm = this.validaInformacionEnvioMensaje();
      //if (validaForm == true && validaFecha.valida == true) {
      if (this.dateValue == "" && this.tipo_hora_envio == "programado") {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "Debe Seleccionar Una Fecha";
        return;
      }
      if (validaFecha == true && this.tipo_hora_envio == "programado") {
          this.mostrarNotificacionAlerta++;
          this.mensaje_warning = `No Se Pueden Procesar Los Mensajes Ya que La Fecha de Programacion debe Ser 20 Minutos despues de la Hora Actual`;
          return;
      }
      if (validaForm == true) {
        let InstFormData = new FormData();
        InstFormData.append("autorizacion", this.autorizacion);
        InstFormData.append("maximocaracteres", this.maximocaracteres);
        InstFormData.append(
          "grupo_seleccionado",
          JSON.stringify(this.grupo_seleccionado)
        );
        InstFormData.append("mensaje_enviar", this.mensaje_enviar);
        InstFormData.append("nombre_campana", this.nombre_campana);
        InstFormData.append("tipo_envio", this.tipo_envio);
        InstFormData.append("tipo_hora_envio", this.tipo_hora_envio);
        InstFormData.append("fecha_envio", this.dateValue);
        InstFormData.append("archivo_envio", this.archivo_envio);
        InstFormData.append("numeros_individual", this.numeros_individual);
        InstFormData.append(
          "metodo_envido_checked_radio",
          this.metodo_envido_checked_radio
        );
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post("/sms/envio/mensajes", InstFormData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.data.status == "OK") {
              if (this.metodo_envido_checked_radio == "individual") {
                this.abrirmodalProgresoMensaje(5, true);
              } else {
                this.abrirmodalProgresoMensaje(11, true);
              }
              this.resetFormularioMensaje();
              this.mensaje_success =
                "Se Estan Procesando Los Mensajes Correctamente";
              this.mostrarNotificacion++;
            }else if (response.data.status == "Error_Horario"){
              this.mostrarNotificacionAlerta++;
              this.mensaje_warning = response.data.message;
              if (response.data.message == "La fecha de envío no puede ser anterior a la fecha actual") {
                  let fecha_programa = new Date();
                  fecha_programa.setDate(fecha_programa.getDate());
                  this.dateValue = this.toISOLocal(fecha_programa).split(".")[0];
              }
            }else{
              this.mostrarNotificacionError++;
            }
          })
          .catch(() => {})
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      } /*else if (validaForm == true && validaFecha.valida == false) {
        if (validaFecha.minutos == true) {
          this.mostrarNotificacionAlerta++;
          this.mensaje_warning = `No Se Pueden Procesar Los Mensajes Ya que La Fecha de Programacion debe Ser 20 Minutos despues de la Hora Actual`;
        } else {
          this.mostrarNotificacionAlerta++;
          this.mensaje_warning = `No Se Pueden Procesar Los Mensajes Ya que Los Esta Enviando Fuera de Horario, Horario Permitido de ${validaFecha.horaInicioConfig} - ${validaFecha.horaFinConfig} Colombia`;
        }
      }*/
    },
    validaInformacionEnvioMensaje() {
      let contador_error = 0;
      if (this.metodo_envido_checked_radio == "individual") {
        //console.log(this.mensaje_enviar,this.tipo_hora_envio,this.tipo_envio,this.numeros_individual);
        if (
          this.mensaje_enviar.trim() == "" ||
          this.tipo_hora_envio == "" ||
          this.tipo_envio == "" ||
          this.numeros_individual.trim() == ""
        ) {
          contador_error++;
        }
      } else if (this.metodo_envido_checked_radio == "archivo") {
        if (
          this.mensaje_enviar.trim() == "" ||
          this.tipo_hora_envio == "" ||
          this.tipo_envio == "" ||
          this.archivo_envio == null
        ) {
          contador_error++;
        }
      } else if (this.metodo_envido_checked_radio == "grupos") {
        if (
          this.mensaje_enviar.trim() == "" ||
          this.tipo_hora_envio == "" ||
          this.tipo_envio == "" ||
          this.grupo_seleccionado == ""
        ) {
          contador_error++;
        }
      }
      if (this.nombre_campana.trim() == "") {
        contador_error++;
      }
      if (contador_error > 0) {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "Debe Completar Los Datos Vacio";
      }
      return contador_error == 0 ? true : false;
    },
    resetFormularioMensaje() {
      let fecha_programa = new Date();
      fecha_programa.setDate(fecha_programa.getDate() + 1);
      this.dateValue = this.toISOLocal(fecha_programa).split(".")[0];
      this.autorizacion = false;
      this.maximocaracteres = 152;
      this.grupo_seleccionado = "";
      this.mensaje_enviar = "";
      this.nombre_campana = "";
      this.tipo_envio = "";
      this.tipo_hora_envio = "";
      this.metodo_envido_checked_radio = "";
      this.fecha_envio = new Date().toISOString().split(".")[0];
      this.archivo_envio = null;
      this.fileInputKey++;
      this.numeros_individual = "";
      this.fechaHorarioDisabled = true;
      document.getElementById("uid-ip8fkjcnavi").checked = false;
      document.getElementById("uid-ip8fkjcnasjl").checked = false;
    },
    convertirHora24X12(hora) {
      let calcula = hora.split(":")[0];
      if (calcula >= 13 && calcula <= 23) {
        calcula = calcula - 12;
        let horamod = hora.split(":");
        horamod[0] = calcula;
        hora = horamod.join(":");
      }
      return hora;
    },
    convertirHora12X24(hora, tiempo) {
      let calcula = hora.split(":")[0];
      if (
        calcula >= 1 &&
        calcula <= 11 &&
        (tiempo == "pm" || tiempo == "p. m.")
      ) {
        calcula = parseInt(calcula) + 12;
        let horamod = hora.split(":");
        horamod[0] = calcula;
        hora = horamod.join(":");
      } else {
        if (tiempo == "a. m.") {
          let calcula1 = hora.split(":")[0];
          hora = calcula1 >= 1 && calcula1 <= 9 ? "0" + hora : hora;
        }
      }
      return hora;
    },
    getParametrosConfiguracion() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/parametros/configuracion", {
          parametros: ["horario_envio_msg", "precio_seguimiento_link"],
        })
        .then((response) => {
          let fuera_horario = this.$store.state.auth.user.sms.fuera_horario;
          this.parametros_config = response.data;
          let valida = this.validaOpcionInmediato();
          if (fuera_horario == 1) {
            this.disabled_fuera_horario = false;
          } else {
            this.disabled_fuera_horario = valida == true ? false : true;
          }
        })
        .catch((e) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    validarFueraDeHorario() {
      let horaInicioConfig = this.parametros_config.horario_envio_msg
        .split(";")[0]
        .split(" ")[0];
      let tiempoInicioConfig = this.parametros_config.horario_envio_msg
        .split(";")[0]
        .split(" ")[1];
      let horaFinConfig = this.parametros_config.horario_envio_msg
        .split(";")[1]
        .split(" ")[0];
      let tiempoFinConfig = this.parametros_config.horario_envio_msg
        .split(";")[1]
        .split(" ")[1];
      horaInicioConfig = this.convertirHora12X24(
        horaInicioConfig,
        tiempoInicioConfig
      );
      horaFinConfig = this.convertirHora12X24(horaFinConfig, tiempoFinConfig);
      let fechaColombia = "";
      let horaColombia = "";
      let tiempoColombia = "";
      let fuera_horario = 0;
      try {
        fuera_horario = this.$store.state.auth.user.sms.fuera_horario;
      } catch (error) {
        fuera_horario = 0;
      }
      let validaFecha = false;
      //console.log(this.dateValue);//2021-10-13T10:30:54
      //console.log(this.calcTimeSetMinutes('-5',20));
      if (this.tipo_hora_envio == "programado" && fuera_horario == 0) {
        fechaColombia = this.dateValue;
        horaColombia = fechaColombia.split("T")[1];
        let comparaFecha = fechaColombia.split("T")[0];
        validaFecha = this.comparaFechaActualProg(comparaFecha);
        let p = horaColombia.substr(0, 5);
        tiempoColombia = p.split(":")[0] >= 12 ? "pm" : "am";
        horaColombia = horaColombia.trim();
        //console.log(horaColombia);
        horaColombia = this.convertirHora24X12(horaColombia);
        //let calcula = horaColombia.split(":")[0];
        //horaColombia = calcula >= 1 && calcula <= 9 ? '0'+horaColombia : horaColombia;
        horaColombia = horaColombia.substr(0, 5);
        //console.log(horaColombia);
        horaColombia = this.convertirHora12X24(horaColombia, tiempoColombia);
        let horaActual = this.calcTimeSetMinutes("-5", 20); //this.obtenerFechaMinutosDespues(20);
        if (horaColombia < horaActual.hora && validaFecha == true) {
          return {
            valida: false,
            minutos: true,
            horaColombia: horaColombia,
            tiempoColombia: tiempoColombia,
            horaInicioConfig: horaInicioConfig,
            horaFinConfig: horaFinConfig,
          };
        }
      } else {
        fechaColombia = this.calcTimeSetMinutes("-5", 1); //this.calcTime('Bogota', '-5');
        horaColombia = fechaColombia.hora;
        tiempoColombia = fechaColombia.tiempo;
        /*console.log(fechaColombia);
          horaColombia = fechaColombia.split(" ")[1];
          tiempoColombia = fechaColombia.split(" ")[2];
          horaColombia = horaColombia.trim();
          horaColombia = this.convertirHora24X12(horaColombia);
          let calcula = horaColombia.split(":")[0];
          horaColombia = calcula >= 1 && calcula <= 9 ? '0'+horaColombia : horaColombia;
          horaColombia = horaColombia.substr(0,5);
          horaColombia = this.convertirHora12X24(horaColombia,tiempoColombia);*/
      }
      //horaColombia = '22:00'
      if (fuera_horario == 1) {
        return {
          valida: true,
          minutos: false,
          horaColombia: horaColombia,
          tiempoColombia: tiempoColombia,
          horaInicioConfig: horaInicioConfig,
          horaFinConfig: horaFinConfig,
        };
      }
      if (horaColombia >= horaInicioConfig && horaColombia < horaFinConfig) {
        return {
          valida: true,
          minutos: false,
          horaColombia: horaColombia,
          tiempoColombia: tiempoColombia,
          horaInicioConfig: horaInicioConfig,
          horaFinConfig: horaFinConfig,
        };
      } else {
        return {
          valida: false,
          minutos: false,
          horaColombia: horaColombia,
          tiempoColombia: tiempoColombia,
          horaInicioConfig: horaInicioConfig,
          horaFinConfig: horaFinConfig,
        };
      }
    },
    comparaFechaActualProg(fechaCompara) {
      //let hoy = new Date().toISOString().split('.')[0]//this.calcTime('Bogota', '-5');
      //hoy = hoy.split("T")[0];
      let hoy = this.calcTime("Bogota", "-5");
      hoy = hoy.split(",")[0];
      hoy = hoy.split("/");
      hoy = `${hoy[2]}-${hoy[1] < 10 ? "0" + hoy[1] : hoy[1]}-${
        hoy[0] < 10 ? "0" + hoy[0] : hoy[0]
      }`;
      if (fechaCompara == hoy) {
        return true;
      } else {
        return false;
      }
    },
    obtenerFechaMinutosDespues(minutos) {
      let fecha_programa = new Date();
      fecha_programa.setMinutes(fecha_programa.getMinutes() + minutos);
      //console.log(this.toISOLocal(fecha_programa).split('.')[0]);
      let fechaColombia = this.toISOLocal(fecha_programa).split(".")[0];
      let horaColombia = fechaColombia.split("T")[1];
      let p = horaColombia.substr(0, 5);
      let tiempoColombia = p.split(":")[0] >= 12 ? "pm" : "am";
      horaColombia = horaColombia.trim();
      horaColombia = this.convertirHora24X12(horaColombia);
      let calcula = horaColombia.split(":")[0];
      horaColombia =
        calcula >= 1 && calcula <= 9 ? "0" + horaColombia : horaColombia;
      horaColombia = horaColombia.substr(0, 5);
      horaColombia = this.convertirHora12X24(horaColombia, tiempoColombia);
      return { hora: horaColombia, tiempo: tiempoColombia };
    },
    calcTime(city, offset) {
      let d = new Date();
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      let nd = new Date(utc + 3600000 * offset);
      return nd.toLocaleString();
    },
    calcTimeSetMinutes(offset, minutos) {
      let d = new Date();
      d.setMinutes(d.getMinutes() + minutos);
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      let nd = new Date(utc + 3600000 * offset);
      //return nd.toLocaleString();
      let fechaColombia = "";
      let horaColombia = "";
      let tiempoColombia = "";
      try {
        horaColombia = nd.toTimeString().substr(0, 5);
        tiempoColombia = "a. m.";
        //console.log(fecha);
        if (
          parseInt(horaColombia.split(":")[0]) >= 12 &&
          parseInt(horaColombia.split(":")[0]) <= 23
        ) {
          tiempoColombia = "p. m.";
        }
      } catch (error) {
        fechaColombia = nd.toLocaleString();
        horaColombia = fechaColombia.split(" ")[1];
        tiempoColombia = fechaColombia.split(" ")[2];
        horaColombia = horaColombia.trim();
        horaColombia = this.convertirHora24X12(horaColombia);
        let calcula = horaColombia.split(":")[0];
        horaColombia =
          calcula >= 1 && calcula <= 9 ? "0" + horaColombia : horaColombia;
        horaColombia = horaColombia.substr(0, 5);
        horaColombia = this.convertirHora12X24(horaColombia, tiempoColombia);
      }
      //console.log({hora:horaColombia,tiempo:tiempoColombia});
      return { hora: horaColombia, tiempo: tiempoColombia };
    },
    validaOpcionInmediato() {
      let horaActual = this.calcTimeSetMinutes("-5", 30); //this.obtenerFechaMinutosDespues(20);
      let horaInicioConfig = this.parametros_config.horario_envio_msg
        .split(";")[0]
        .split(" ")[0];
      let tiempoInicioConfig = this.parametros_config.horario_envio_msg
        .split(";")[0]
        .split(" ")[1];
      let horaFinConfig = this.parametros_config.horario_envio_msg
        .split(";")[1]
        .split(" ")[0];
      let tiempoFinConfig = this.parametros_config.horario_envio_msg
        .split(";")[1]
        .split(" ")[1];
      horaInicioConfig = this.convertirHora12X24(
        horaInicioConfig,
        tiempoInicioConfig
      );
      horaFinConfig = this.convertirHora12X24(horaFinConfig, tiempoFinConfig);
      if (
        horaActual.hora >= horaInicioConfig &&
        horaActual.hora < horaFinConfig
      ) {
        return true;
      } else {
        return false;
      }
    },
    calculaHoraProgramacion() {
        let fechaColombia = this.dateValue;
        let horaColombia = fechaColombia.split("T")[1];
        let comparaFecha = fechaColombia.split("T")[0];
        let validaFecha = this.comparaFechaActualProg(comparaFecha);
        let p = horaColombia.substr(0, 5);
        let tiempoColombia = p.split(":")[0] >= 12 ? "pm" : "am";
        horaColombia = horaColombia.trim();
        //console.log(horaColombia);
        horaColombia = this.convertirHora24X12(horaColombia);
        //letfechaColombia calcula = horaColombia.split(":")[0];
        //horaColombia = calcula >= 1 && calcula <= 9 ? '0'+horaColombia : horaColombia;
        horaColombia = horaColombia.substr(0, 5);
        //console.log(horaColombia);
        horaColombia = this.convertirHora12X24(horaColombia, tiempoColombia);
        let horaActual = this.calcTimeSetMinutes("-5", 20); //this.obtenerFechaMinutosDespues(20);
        if (horaColombia < horaActual.hora && validaFecha == true) {
          return true;
        }
        return false;
    },
    limitarCaracteres(event) {
      let value = event.target.value;
      event.target.value = this.NumText(value);
      this.mensaje_enviar = this.NumText(value);
    },
    limitarCaracteresV2(event) {
      let value = event.target.value;
      event.target.value = this.NumTextV2(value);
      this.nombre_campana = this.NumTextV2(value);
    },
    NumText(string) {
      //solo letras y numeros
      var out = "";
      //Se añaden las letras validas
      //áéíóúÁÉÍÓÚ
      var filtro =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!¡#$%&/()=?¿[]{}.:,;-_+*@>< ñÑ"; //Caracteres validos

      for (var i = 0; i < string.length; i++)
        if (filtro.indexOf(string.charAt(i)) != -1) out += string.charAt(i);
      return out;
    },
    NumTextV2(string) {
      //solo letras y numeros
      var out = "";
      //Se añaden las letras validas
      //áéíóúÁÉÍÓÚ
      var filtro =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_ ñÑ"; //Caracteres validos

      for (var i = 0; i < string.length; i++)
        if (filtro.indexOf(string.charAt(i)) != -1) out += string.charAt(i);
      return out;
    },
    obtenerTipoEnvio() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/parametros/tipo_envio")
        .then((response) => {
          let data = response.data;
          this.listaTipoEnvioConfig = data;
          this.descripcionHorarioTipoEnvio = data[0].horario_descripcion;
          let lista = [];
          for (const iterator of data) {
            let precio = iterator.precio;
            let separa = precio.split(".");
            if (parseInt(separa[1]) == 0) {
              precio = separa[0];
            }
            if (iterator.tipo_envio == "1_via") {
              lista.push({
                label: `1 Via [ Valor del SMS ${precio} crédito(s) ] `,
                value: "1via",
              });
            }
            if (iterator.tipo_envio == "2_via") {
              lista.push({
                label: `2 Via [ Valor del SMS ${precio} crédito(s) ]`,
                value: "2via",
              });
            }
            if (iterator.tipo_envio == "flash") {
              lista.push({
                label: `Flash [ Valor del SMS ${precio} crédito(s) ]`,
                value: "flash",
              });
            }
          }
          this.optionsTipoEnvio = lista;
        })
        .catch((e) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    abrirmodalLinkMultimedia() {
      this.$root.$emit("abrirModalLinkMultimedia", true);
      //this.openModalMultimedia = true;
      //setTimeout(()=>{this.openModalMultimedia = false},100);
    },
    abrirmodalProgresoMensaje(tiempo, abrir) {
      this.$root.$emit("abrirmodalProgresoMensaje", tiempo, abrir);
      //this.openModalMultimedia = true;
      //setTimeout(()=>{this.openModalMultimedia = false},100);
    },
    abrirmodalSeguimientoLink() {
      this.$root.$emit(
        "abrirmodalSeguimientoLink",
        true,
        this.parametros_config.precio_seguimiento_link
      );
      //this.openModalMultimedia = true;
      //setTimeout(()=>{this.openModalMultimedia = false},100);
    },
  },
  mounted() {
    setTimeout(() => {
      this.getParametrosConfiguracion();
      let fecha_programa = new Date();
      fecha_programa.setDate(fecha_programa.getDate());
      this.dateValue = this.toISOLocal(fecha_programa).split(".")[0]; //this.calcTime('-5').toISOLocal().split('.')[0];
      //this.dateValue = this.calcTime('-5').toLocaleString();
      //let horafecha = this.calcTime('-5').toISOString().split('.')[0];
      this.getGrupos();
      this.obtenerTipoEnvio();
    }, 100);
    setTimeout(() => {
      //firma dinamica
      let firma = this.$store.state.auth.user.sms.firma_sms;
      this.longitud_firma_sms = firma.length + 1;
      let char1=160,char2=306,char3=459,char4=612,char5=765; 
      this.maximocaracteres = char1-this.longitud_firma_sms;
      this.lista_parametros_caracteres = [
                    {
                      value: `${char1-this.longitud_firma_sms}`,
                      label: `Desde 1 hasta ${char1-this.longitud_firma_sms} Caracteres (Se cobra el valor de 1 SMS)`,
                    },
                    {
                      value: `${char2-this.longitud_firma_sms}`,
                      label: `Desde ${char1+1} Hasta ${char2-this.longitud_firma_sms} Caracteres (Se cobra el valor de 2 SMS)`,
                    },
                    {
                      value: `${char3-this.longitud_firma_sms}`,
                      label: `Desde ${char2+1} Hasta ${char3-this.longitud_firma_sms} Caracteres (Se cobra el valor de 3 SMS)`,
                    },
                    {
                      value: `${char4-this.longitud_firma_sms}`,
                      label: `Desde ${char3+1} Hasta ${char4-this.longitud_firma_sms} Caracteres (Se cobra el valor de 4 SMS)`,
                    },
                    {
                      value: `${char5-this.longitud_firma_sms}`,
                      label: `Desde ${char4+1} Hasta ${char5-this.longitud_firma_sms} Caracteres (Se cobra el valor de 5 SMS)`,
                    },
                  ];
    },500);
    /*setTimeout(() => {
      try {
         let fuera_horario = this.$store.state.auth.user.sms.fuera_horario;
         this.disabled_fuera_horario = fuera_horario == 0 ? true : false;
         let valida_inmediato = this.validaOpcionInmediato();
         console.log(valida_inmediato);
       } catch (error) {
          let valida_inmediato = this.validaOpcionInmediato();
          console.log(valida_inmediato);
          this.disabled_fuera_horario = true;
       }
    },200)*/
    //setTimeout(() => {this.validarFueraDeHorario();},200)
  },
  computed: {
    changeRadioMetodoEnvio() {
      let metodo_envido_checked = 0;
      if (this.metodo_envido_checked_radio == "individual") {
        metodo_envido_checked = 1;
      } else if (this.metodo_envido_checked_radio == "archivo") {
        metodo_envido_checked = 2;
      } else if (this.metodo_envido_checked_radio == "grupos") {
        metodo_envido_checked = 3;
      }
      return metodo_envido_checked;
    },
    isDisabled: function () {
      return !this.autorizacion;
    },
    imagenAyuda() {
      return `${this.$apiAdress}/dist/img/ayuda_mensajes.jpg`;
    },
    changeRadioTipoEnvio() {
      let tipo_envioc = this.tipo_envio;
      let ruta_config = this.listaTipoEnvioConfig.find((item) => {
        return (item.tipo_envio.split("_")).join("") == tipo_envioc;
      });
      if (ruta_config != undefined) {
          this.descripcionHorarioTipoEnvio = ruta_config.horario_descripcion;
      }
      return "";
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>